console.log(`
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣷⣄⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢰⣄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠙⣎⠙⠲⢤⣄⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡞⠈⠳⢦⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣀⣀⣈⣷⣄⡀⠈⠉⠉⠛⠓⠒⠲⠶⢤⣄⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⡇⠀⠀⠀⠈⠛⠳⠶⠦⠤⠤⠶⠒⠛⠛⠉⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠙⠳⣤⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠙⢦⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⣇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠙⢦⡀⠀⡀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⡤⠖⠋⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠻⣴⣷⠀⠀⠀⠀⠀⠀
    ⣶⠤⠤⠤⠖⠒⠚⠛⠋⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢻⠘⡇⠀⠀⠀⠀⠀
    ⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⡆⠸⡆⠀⠀⢀⠀
    ⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⠇⠀⢳⠀⢀⣾⠀
    ⢹⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣠⠞⣾⠀
    ⠈⣷⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⠋⣴⠃⠀
    ⣀⠘⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣴⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣸⡶⠃⠀⠀
    ⠉⢿⡻⣷⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⠀⠀⠀⠀⠀⣀⣤⠞⠉⠀⢻⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣰⣿⠀⠀⠀⠀
    ⠀⠀⠉⠻⣷⡄⠀⠀⠀⣰⠂⠀⠀⢀⣠⣴⡶⠶⠶⠶⢶⣶⣶⡿⠁⠀⠀⠀⠀⠀⠻⣿⣶⣶⣶⣾⣿⣷⣶⣶⣶⣮⣿⣄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⣧⠀⠀⠀
    ⠀⠀⠀⠀⠀⠙⢦⡀⢀⡇⠀⠠⢤⣤⣀⣀⣀⣀⠀⠀⣀⣀⣀⣀⣀⣤⣤⣤⠶⠒⠛⠋⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠙⢷⣄⠀⠀⠀⠀⠀⠀⡄⠀⠀⠀⠀⢸⡄⠀⠀
    ⠀⠀⠀⠀⣀⡤⠼⠛⣻⠃⠀⠀⢸⠁⢉⣭⣭⣿⣿⣿⣿⣽⣯⣉⣀⠀⠀⠀⠀⢀⡤⣴⣶⣶⣾⣾⣶⣶⣶⠶⠶⠦⠤⣀⡀⠙⠻⣦⣄⡆⠀⠀⣿⣀⠀⠀⠀⢸⠃⠀⠀
    ⠀⠀⠀⠘⢿⡇⠀⠒⣿⠀⠀⠀⢸⡀⠀⠘⣿⣿⣿⣿⣿⠁⠀⠀⢹⠀⠀⠀⠀⢿⠀⠸⣿⣿⣿⣿⣿⣿⡇⠀⠀⠀⠀⣠⠇⠀⠀⠀⢹⡇⠀⠀⢿⡉⠙⠲⠶⠾⢤⣀⡀
    ⠀⠀⠀⠀⠈⠻⣦⠀⣿⠀⠀⠀⢸⡇⣄⠀⠙⠻⠿⠿⠋⠀⠀⣠⠏⠀⠀⠀⠀⠈⢧⡀⠈⠛⠿⠿⠟⠋⠀⠀⠀⠀⡴⠋⠀⠀⠀⠀⢸⡇⠀⠀⢸⠛⠓⠒⠂⠀⢀⡿⠃
    ⠀⠀⠀⠀⠀⠀⠈⠛⢿⠀⠀⠀⢸⡇⠘⢧⣄⠀⠀⠀⣀⣤⣾⠁⠀⠀⠀⠀⠀⠀⠀⠙⣦⣄⠀⠀⠀⠀⠀⣠⡴⠞⠁⠀⠀⠀⠀⠀⢸⡇⠀⠀⢸⡇⠀⠀⣠⡴⠛⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⢸⡀⠀⠀⢸⡇⠀⠀⠙⠛⠛⠛⢉⡼⠁⣠⡀⠀⠀⠀⠀⠀⠀⠀⠹⣌⠛⠳⠶⠿⠛⠁⠀⠀⠀⠀⠀⠀⠀⠀⢸⡇⠀⠀⢸⡷⠖⢛⡟⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠸⡇⠀⠀⢸⣷⠀⠀⠀⠀⠀⠀⠉⠀⠀⠘⠳⠦⠀⠀⠀⠀⠀⠀⠀⠈⠳⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⡇⠀⠀⠘⡇⠀⣸⠁⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⣧⠀⠀⢸⣿⣆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢈⣿⠀⠀⠀⡇⢀⡇⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⡇⠀⢸⣿⠻⣧⡀⠀⠀⠀⠀⠀⠀⢠⡇⢀⣀⣀⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⡴⠟⢻⠀⠀⢰⡇⢸⡇⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢻⣄⢸⠙⡇⡟⢿⣦⣄⠀⠀⠀⠀⠛⠋⠉⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⣠⠴⠛⠉⠀⣠⣿⣇⠀⢸⠁⠸⡇⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠹⠏⢀⣷⠃⠀⡏⠙⠷⣶⣤⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣀⣤⣴⡶⠛⠉⠀⠀⠀⠀⢰⠃⠀⠹⣄⣾⣀⡀⢳⡀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⡟⠀⠀⣧⠀⠀⣀⣬⡍⠙⠛⠓⠒⠒⠲⣶⣶⠒⠒⠚⠛⠋⠉⠉⠀⠘⣿⠀⠀⠀⠀⠀⢠⡇⠀⠀⠀⠹⠏⠈⠉⠛⠓⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⠁⡀⠀⡼⠞⠋⠁⠀⠻⡄⠀⠀⢀⣤⠞⠁⢹⡆⠀⠀⠀⠀⠀⠀⠀⠀⢹⡗⠦⣤⡀⠀⢸⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣷⡀⠀⠀⠀⠀⠀⢠⡹⣦⠖⠉⠀⠀⣠⣾⡇⠀⠀⠀⠀⠀⠀⠀⠀⣸⠉⣦⡀⠙⠳⣼⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢘⣷⠀⠀⠀⠀⠀⣾⠇⠁⠀⠀⠀⣴⡇⣿⠀⠀⠀⠀⠀⠀⠀⠀⢰⡇⢰⠏⠻⣦⡀⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣾⠁⠀⠀⠀⠀⠈⣷⡀⠀⠀⢀⡼⠋⣷⠸⣆⠀⠀⠀⠀⠀⠀⢰⠏⢠⡟⠀⠀⠈⠻⣦⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣠⠤⠶⠻⢿⠛⠛⠛⠋⠙⠛⢻⡟⠛⠒⠾⣧⣤⡘⣷⡘⣆⠀⠀⠀⠀⣴⠋⣠⡟⠀⠀⠀⠀⠀⠈⠻⣆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⡿⠶⢶⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⡶⠶⣿⡇⠙⢷⣌⠳⣄⡤⠞⢁⣼⠏⠀⠀⣤⡀⠀⠀⠀⠀⠈⢷⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⡇⠀⠀⠀⠈⠉⠉⠉⠉⠉⠉⠉⠉⠀⠀⠀⠠⣿⣛⠶⣾⡏⠓⠾⠶⠶⢿⡁⣠⢶⣶⠋⣷⠀⠀⠀⠀⠀⠈⢻⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⣇⣼⣷⣯⣿⣦⣤⡄⠀⠀⠻⣇⡜⠁⢀⣿⠀⠀⠀⠀⠀⠀⠀⢻⡄⠀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢷⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣸⣿⣤⣀⠀⠀⠈⠙⠛⠀⠀⠀⠈⢣⣄⡾⠁⠀⠀⠀⠀⠀⠀⠀⠀⢻⡀⠀⠀⠀⠀⠀⠀⠀
    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣰⣟⣵⣿⠉⠙⠶⢤⡀⠀⠀⠀⠀⠀⠀⢻⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⢾⣇⠀⠀⠀⠀⠀⠀⠀
`);
